'use strict';

angular.module('aerosApp')
    .controller("CustomerDashboardController", CustomerDashboardController);

CustomerDashboardController.$inject = ["$rootScope", "$scope", "$state", "$location", "$http", "changeOrganizationModal",
    "rbacService", 'UserProfileService', "$uibModal", "Notification", "messageService", "allPermissions", "allRoles",
    "SubMenuService", "$window", "ProjectInfoModal", "CommonService", "SessionService", "aflPageInstructionsModal",
    "ShowUserProfileModal", "routesConstant", "rolesConstant", "PageService"];

function CustomerDashboardController($rootScope, $scope, $state, $location, $http, changeOrganizationModal, rbacService,
                                     UserProfileService, $uibModal, Notification, messageService, allPermissions,
                                     allRoles, SubMenuService, $window, ProjectInfoModal, CommonService, SessionService,
                                     aflPageInstructionsModal, ShowUserProfileModal, routesConstant, rolesConstant,
                                     PageService) {

    var devEnv = ["local", "dev", "uat", "int", "automation"];

    var CUSTOMER = $scope.CUSTOMER = routesConstant.CUSTOMER;
    var LOGIN = $scope.LOGIN = routesConstant.LOGIN;
    $scope.ADMIN = routesConstant.ADMIN;
    $scope.MFG = routesConstant.MFG;

    $scope.STATIC = window.STATIC;

    angular.extend($scope, {
        SubMenuService: SubMenuService,
        $state: $state,
        showProjectInfoModal: showProjectInfoModal,
        isActive: isActive,
        loadOrganization: loadOrganization,
        initUserForOrganization: initUserForOrganization,
        loadOrganizationUsers: loadOrganizationUsers,
        changeDefaultOrganization: changeDefaultOrganization,
        changeOrganization: changeOrganization,
        changePassword: changePassword,
        showUserProfileModal: showUserProfileModal,
        showInstructions: showInstructions
    });

    (function init() {

        CommonService.getAppInfo()
            .then(function (appInfo) {
                $scope.appInfo = appInfo;
            }, function(error) {
                // if error, let's try again
                CommonService.getAppInfo()
                    .then(function (appInfo) {
                        $scope.appInfo = appInfo;
                    });
            });

        PageService.setCurrentPage(PageService.getCurrentPage() || {
            title: undefined,
            type: undefined,
            instructions: "Loading",
            actions: [],
            showInstructions: showInstructions
        });

        var sessionInfo = SessionService.getSessionInfo();
        if (sessionInfo) {
            $scope.organizationId = sessionInfo.organization.id;
        }

        messageService.getMessages().then(function (config) {
            var activeMessages = config.getMessagesByTarget('Aeros');
            var now = new Date().getTime();
            _.each(activeMessages, function (msg) {
                var start = new Date(msg.startDt).getTime();
                var end = new Date(msg.endDt).getTime();
                if (start < now && now < end) {
                    Notification.success({
                        message: msg.message,
                        delay: false
                    });
                }
            });
        });

        initUserForOrganization($scope.organizationId);
    }());

    function showInstructions() {
        aflPageInstructionsModal.open($rootScope.appInfo.aerosUserGuideUrl);
    }

    function showProjectInfoModal() {
        ProjectInfoModal.open($rootScope.currentProject);
    }

    function isActive(viewLocation) {
        return $location.path().indexOf(viewLocation) === 0;
    }

    function loadOrganization(organizationId) {
        CommonService.loadOrganization(organizationId)
            .success(function (data) {
                $scope.organization = data.organizations;
            });
    }

    /*  Loads profile for a user within an organization
     * */
    function initUserForOrganization(organizationId) {

        $rootScope.orgId = organizationId;

        UserProfileService.fetch(organizationId, true)
            .then(function (data) {
                setProfileData($scope, data);
                if ($state.current.name === CUSTOMER.DASHBOARD.stateName) {
                    $state.go(CUSTOMER.PROJECTS.stateName);
                }
            });
    }

    function setProfileData(ctrl, data) {
        // Some of this information is available in the session info, but it is cached and only
        // updated periodically. So, we want to get it from the organization data call we just
        // made.
        ctrl.organization = data.organization;
        ctrl.organizationName = data.organization.name;
        ctrl.userFullName = data.user.fullName;
        ctrl.features = _.map(ctrl.organization.features, function (feature) {
            return feature.name.toLowerCase(); // feature.name "Inspection" vs. setup.type "INSPECTION"
        });
        ctrl.user = data.user;
        ctrl.roles = data.roles;
        ctrl.me = {
            roles: ctrl.roles
        };
        ctrl.isOrgAdmin = data.isOrgAdmin;
        ctrl.userHasMultipleOrgs = (data.orgCount > 1);
        ctrl.isProjectManager = data.isProjectManager;
        if (data.isProjectSupervisor) {
            ctrl.roles.push("anyProjectSupervisor");
        }
        if (data.organization.license) {
            ctrl.accountRoles = [data.organization.license];
        } else {
            ctrl.accountRoles = ["Professional"];
        }
        ctrl.usage = data.usage;
        ctrl.usage.percentTotalAllowed = Math.round(ctrl.usage.percentTotalAllowed * 100);
        ctrl.usage.capacity = 100 - ctrl.usage.percentTotalAllowed;
        rbacService.getScope(ctrl);
        rbacService.setAllPermissions(allPermissions);
        ctrl.allow = {};
        ctrl.allowMfg = {};
        ctrl.allowAdmin = {};
        // TODO: AEROS-1496
        ctrl.isSuspendedAdmin = data.isSuspendedAdmin;

        _.each(_.keys(allPermissions.permissions), function (permission) {
            ctrl.allow[permission] = rbacService.checkAccess(permission);
        });
        _.each(_.keys(allPermissions.accountPermissions), function (permission) {
            ctrl.allow[permission] = rbacService.checkAccess(permission);
        });
        _.each(_.keys(allPermissions.adminPermissions), function (permission) {
            ctrl.allowMfg[permission] = rbacService.checkMfgAccess(permission);
        });

        _.each(_.keys(allPermissions.adminPermissions), function (permission) {
            ctrl.allowAdmin[permission] = rbacService.checkAdminAccess(permission);
        });

        // These settings are related to the portal switching options in the user menu.
        // They define what portals the user has access to and the current portal the user is on.
        $scope.hasAdminPortalRole = $scope.hasMfgPortalRole = $scope.hasCustomerPortalRole = false;
        var appAccess = SessionService.getAppAccess();
        if (appAccess) {
            _.each(appAccess, function (access) {
                if (access === rolesConstant.ADMIN) {
                    $scope.hasAdminPortalRole = true;
                } else if (access === rolesConstant.MFG) {
                    $scope.hasMfgPortalRole = true;
                } else if (access === rolesConstant.CUSTOMER) {
                    $scope.hasCustomerPortalRole = true;
                }
            })
        }
        $scope.role = rolesConstant.CUSTOMER;

        var isDevEnv;
        _.each(devEnv, function (env) {
            if ($location.host().indexOf(env) > -1) {
                isDevEnv = true;
            }
        });
        ctrl.allow.sampleResults = isDevEnv;
        // TODO: AEROS-1496
        ctrl.allow.viewOnlyProfile = ctrl.isSuspendedAdmin;
        ctrl.commonReady = true;
    }

    function loadOrganizationUsers(organizationId) {
        return CommonService.loadOrganizationUsers(organizationId)
            .success(function (data) {
                return $scope.users = data.users;
            });
    }

    function changeDefaultOrganization(organization) {
        CommonService.changeDefaultOrganization(organization.id)
            .then(function(results) {
                CommonService.loadOrganization(organization.id)
                    .then(function(loadOrgResults) {
                        SessionService.updateSessionOrganization(loadOrgResults.data.organizations);
                        $state.go(CUSTOMER.PROJECTS.stateName,{}, {reload: true});
                    }, function(error) {
                        Notification.error(error.data.message + ': ' + error.data.detail);
                        if (error.status === 403) {
                            $state.go( CUSTOMER.ORGANIZATION_PROFILE.stateName, {id: organization.id});
                        }
                    });
            });
    }

    function changeOrganization(oldId) {
        changeOrganizationModal.open(oldId).then(function (newOrg) {
            if (newOrg.id !== oldId) {
                $scope.changeDefaultOrganization(newOrg);
            }
        });
    }

    function changePassword(username, linkToServer) {
        CommonService.changePassword(username)
            .then(function (rtn) {
                if (rtn.data.token) {
                    window.location.href = "/" + linkToServer + "/" + encodeURIComponent(username)
                        + "/" + rtn.data.token;
                } else {
                    $state.go(LOGIN.LOGOUT.stateName);
                }
            });
    }

    function showUserProfileModal(user, vendorLogoId, organizationId) {
        ShowUserProfileModal.open(user, vendorLogoId, organizationId)
            .then(function () {
                $window.location.reload();
            });
    }

}
